<template>
  <div>
    <h3
      class="text-2xl text-dokBlue-ultra font-semibold border-0 border-b-2 border-solid pb-2 mb-4"
    >
      <i class="fas fa-users"></i> Antécédents familiaux
    </h3>
    <div>
      <div class="grid gap-2 grid-cols-1 items-center">
        <label
          >Un membre de votre famille souffre-il ou a-t-il souffert de ?
          (parents / grand parents)</label
        >
        <!-- <toggle-button v-model="showFamilyBlock" :sync="true" :labels="{checked: 'On', unchecked: 'Off'}"/> -->
      </div>
    </div>
    <div class="flex justify-end mb-5">
      <p class="text-xs text-gray-500">
        (Choisissez une maladie de la liste et saisissez le lien parental puis
        cliquer sur <i class="fa fa-plus"></i> pour sauvegarder)
      </p>
    </div>
    <div class="flex flex-col w-full mb-20">
      <div class="flex flex-col" v-if="showFamilyBlock">
        <div class="">
          <div
            class="flex justify-center lg:justify-end mr-2 w-full lg:w-1/2 ml-auto"
          >
            <div class="relative border-none mr-2 w-1/2">
              <multiselect
                v-model="selectedAntcFams"
                :options="antecedentFamiliaux"
                :searchable="true"
                track-by="name"
                label="name"
                :multiple="true"
                placeholder="Sélectionnez pathologie"
                :class="error ? 'has-error' : ''"
                selectLabel=""
                :max="3"
                :taggable="true"
                @tag="addTag"
              ></multiselect>
            </div>
            <div class="relative border-none mr-2 w-5/12">
              <select
                v-model="curentAntcFamMoreInfo"
                class="bg-white text-dokBlue-ultra appearance-none border border-solid broder-g inline-block py-2 pl-3 pr-8 rounded h-full w-full"
              >
                <option
                  v-for="(val, indx) in lienParente"
                  :key="indx"
                  :value="val"
                  >{{ val }}</option
                >
              </select>
              <i
                class="fas fa-caret-up fa-flip-vertical fa-lg absolute"
                style="top:15px;right:8px;"
              ></i>
            </div>
            <button
              @click="addAntcedToPatien"
              v-if="selectedAntcFams.length > 0"
              class="bg-transparent border border-dokBlue-ultra py-2 px-2 rounded-cu text-dokBlue-ultra cursor-pointer"
              style="width:34px"
            >
              <i class="fas fa-plus"></i>
            </button>
          </div>
          <div class="flex justify-center lg:justify-end w-full py-5">
            <table class="table-auto w-full lg:w-1/2">
              <!-- <thead>
                <tr>
                  <th class="px-4 py-2 bg-dokBlue-ultra text-white lg:w-3/4">Antécédent</th>
                  <th class="px-4 py-2 bg-dokBlue-ultra text-white lg:w-1/4">Lien de parenté</th>
                  <th class="px-4 py-2 bg-dokBlue-ultra text-white"></th>
                </tr>
              </thead> -->
              <tbody>
                <familial
                  :patient="patient"
                  v-for="(antf, indx) in patientAtecedents"
                  :key="indx"
                  :familial="antf"
                  v-on:removeAntecedFamil="removeAntecedFamil"
                >
                </familial>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const toggleInput = () => import("@/views/global-components/toggle");
const Familial = () => import("./familial");
import { remove, findIndex } from "lodash";

export default {
  props: ["patient"],
  components: { toggleInput, Familial },
  data() {
    // console.error("fdataaaaa")
    return {
      selectedAntcFams: [],
      curentAntcFam: {},
      curentAntcFamName: "",
      curentAntcFamMoreInfo: "",
      antecedFamMoreInfo: "",
      showFamilyBlock: false,
      antecedentFamiliaux: [],
      patientAtecedentsId: [],
      patientAtecedents: [],
      error: false,
      lienParente: [
        "Père",
        "Mère",
        "frère",
        "soeur",
        "Grand-père paternel",
        "Grand-mère paternel",
        "Grand-père maternel",
        "Grand-mère maternel"
      ]
    };
  },
  mounted() {
    var el = this;
    this.$store.dispatch("healthbook/GET_ANTECEDENT_FAMILIAUX_LIST", {
      patientId: this.patient._id,
      onData: this.onLoadData
    });
  },
  watch: {
    // showFamilyBlock: function(newVal) {
    //   const el = this
    //   if(!newVal && this.patientAtecedents.length >0) {
    //     var vonf = confirm("En désactivant vous risquer de tout supprimer. \nVoulez-vous vraiement confirmer cette action ?")
    //     if(vonf) {
    //       this.$store
    //       .dispatch("healthbook/REMOVE_ALL_PATIENT_ANTECEDENT_FAMILIAUX", {
    //         patientId: this.patient._id,
    //         onData:function({ok}) {
    //           if(ok) {
    //             el.patientAtecedents = [];
    //             for(var i = 0; i < el.antecedentFamiliaux.length; i++) {
    //               delete el.antecedentFamiliaux[i].$isDisabled;
    //             }
    //           }
    //           el.$forceUpdate();
    //         }
    //       });
    //     } else {
    //       setTimeout(function(){
    //         el.showFamilyBlock = true
    //       }, 200);
    //     }
    //   }
    // },
  },
  methods: {
    addTag(newTag) {
      const tag = {
        name: newTag
      };
      this.selectedAntcFams.push(tag);
    },
    onLoadData(response) {
      this.antecedentFamiliaux = response.data.datas;
      this.patientAtecedents = response.data.patient
        ? response.data.patient
        : [];
      this.showFamilyBlock = this.patientAtecedents.length > 0;
      for (var i = 0; i < this.patientAtecedents.length; i++) {
        this.ableDisableFromList(this.patientAtecedents[i], true);
      }
      this.showFamilyBlock = true;
      this.$forceUpdate();
    },
    async addAntcedToPatien() {
      if (!this.selectedAntcFams.length) {
        this.error = true;
        return;
      }
      this.curentAntcFam = {
        name: this.selectedAntcFams[0].name,
        moreInfo: this.curentAntcFamMoreInfo
      };
      // this.curentAntcFamName = this.selectedAntcFams[0].name;
      this.$store.dispatch("healthbook/SAVE_PATIENT_ANTECEDENT_FAMILIAL", {
        patientId: this.patient._id,
        data: {
          antecedent: this.curentAntcFam.antecedent
            ? this.curentAntcFam.antecedent._id
            : null,
          name: this.curentAntcFam.name,
          moreInfo: this.curentAntcFam.moreInfo
        },
        onData: this.onData
      });
    },
    onData({ data, ok, message }) {
      this.error = false;
      const el = this;
      this.ableDisableFromList(Object.assign({}, this.curentAntcFam), true);

      if (ok) {
        this.selectedAntcFams = [];
        this.patientAtecedents.push(data);
        this.curentAntcFam = {
          antecedent: null,
          name: "",
          moreInfo: ""
        };
      }
      this.$forceUpdate();
    },
    removeAntecedFamil(famil) {
      remove(this.patientAtecedents, function(o) {
        return o.antecedent && o.antecedent == famil.antecedent;
      });
      this.ableDisableFromList(famil, false);
      this.$forceUpdate();
    },
    async ableDisableFromList(objSear, value) {
      if (!objSear.anteceden) return;
      const index = findIndex(this.antecedentFamiliaux, function(o) {
        return o._id === objSear.antecedent._id;
      });
      if (index > -1) {
        this.antecedentFamiliaux[index].$isDisabled = value;
      }
    }
  }
};
</script>

<style lang="scss">
.choose-antecent-familiaux,
.antcedent-mediacal-list {
  .vs--searchable {
    .vs__selected-options {
      .vs__search {
        margin-top: 0;
        height: 34px;
      }
    }
    &.has-error {
      // background-color: blue;
      .vs__dropdown-toggle {
        border: 1px solid red;
      }
    }
  }
}
</style>
